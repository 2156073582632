import { styled } from 'buttered';
import { useId } from '@react-aria/utils';
import React from 'react';
import { Squircle } from './squircle';

let Wrapper = styled('figure')`
  display: flex;
  margin: 0px auto 0px auto;
  width: fit-content;
`;

let TextWrapper = styled('p')`
  height: 60px;
  line-height: 60px;
  margin-left: 15px;
  font-size: 26px;
  font-weight: 500;
`;

export let HeaderLogo = ({
  logo,
  children
}: {
  logo: React.ReactNode;
  children: React.ReactNode;
}) => {
  let id = useId();

  return (
    <Wrapper aria-labelledby={id}>
      <Squircle size={60} color="white">
        {logo}
      </Squircle>
      <TextWrapper id={id}>{children}</TextWrapper>
    </Wrapper>
  );
};
