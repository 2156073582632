import React from 'react';

export let TeamIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#FFC267"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M61.6667 71V66.3333C61.6667 63.858 60.6833 61.484 58.933 59.7337C57.1827 57.9833 54.8087 57 52.3333 57H33.6667C31.1913 57 28.8174 57.9833 27.067 59.7337C25.3167 61.484 24.3333 63.858 24.3333 66.3333V71"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M43 47.6667C48.1546 47.6667 52.3333 43.488 52.3333 38.3333C52.3333 33.1787 48.1546 29 43 29C37.8453 29 33.6667 33.1787 33.6667 38.3333C33.6667 43.488 37.8453 47.6667 43 47.6667Z"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.6667 71V66.3333C75.6651 64.2654 74.9768 62.2565 73.7098 60.6221C72.4429 58.9877 70.669 57.8203 68.6667 57.3033"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.3333 29.3033C61.341 29.8174 63.1204 30.985 64.3912 32.6221C65.6619 34.2591 66.3516 36.2726 66.3516 38.345C66.3516 40.4174 65.6619 42.4309 64.3912 44.0679C63.1204 45.705 61.341 46.8726 59.3333 47.3867"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="56" height="56" fill="white" transform="translate(22 22)" />
        </clipPath>
      </defs>
    </svg>
  );
};
