import { Text } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';

let Wrapper = styled('div')`
  margin: 20vh auto;
  max-width: 1200px;
  text-align: center;
  padding: 0px 20px;

  h1 {
    font-size: 60px;
    font-weight: 800;
  }

  @media screen and (max-width: 500px) {
    margin: 50px auto;

    h1 {
      font-size: 30px;
      font-weight: 800;
    }
  }
`;

export let ReallyBigText = ({ children }: { children: React.ReactNode }) => {
  return (
    <Wrapper>
      <h1>{children}</h1>
    </Wrapper>
  );
};
