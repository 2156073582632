import { styled } from 'buttered';

export let HighlightSection = styled('header')`
  display: flex;
  flex-direction: column;
  background-size: cover;
  position: relative;
  color: white;
`;

export let HighlightSectionInner = styled('div')`
  max-width: 1200px;
  margin: 10vh auto;
  padding: 0px 10px;

  @media screen and (max-width: 720px) {
    margin: max(10vh, 20px) auto max(6vh, 20px) auto;
  }
`;
