import { styled } from 'buttered';

export let BigSubtitle = styled('h2')`
  font-size: 2.3em;
  font-weight: 600;

  @media screen and (max-width: 900px) {
    font-size: 1.8em;
  }

  @media screen and (max-width: 650px) {
    font-size: 1.3em;
  }
`;
