import { styled } from 'buttered';

export let Title = styled('h1')`
  font-size: 2.8em;
  font-weight: 800;

  @media screen and (max-width: 600px) {
    font-size: 2.4em;
  }
`;

export let BigTitle = styled('h1')`
  font-size: 3.4em;
  font-weight: 800;

  @media screen and (max-width: 600px) {
    font-size: 2.4em;
  }
`;
