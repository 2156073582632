import React from 'react';

export let MobileIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#FFBB00"
      />
      <g clipPath="url(#mobcl2)">
        <path
          d="M62.1429 23H37.8571C35.1746 23 33 25.3987 33 28.3576V71.2182C33 74.1771 35.1746 76.5758 37.8571 76.5758H62.1429C64.8254 76.5758 67 74.1771 67 71.2182V28.3576C67 25.3987 64.8254 23 62.1429 23Z"
          fill="white"
          fillOpacity="0.7"
        />
        <path
          d="M50 69H50.022"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M46 27H54"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="mobcl2">
          <rect width="34" height="53.5758" fill="white" transform="translate(33 23)" />
        </clipPath>
      </defs>
    </svg>
  );
};
