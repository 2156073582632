import React, { Fragment } from 'react';
import { styled } from 'buttered';
import { Spacer } from '@vapor/ui';

let Wrapper = styled('div')`
  display: flex;
  width: fit-content;
  margin: 40px auto 20px auto;

  @media screen and (max-width: 720px) {
    margin: 20px auto 15px auto;
  }

  @media screen and (max-width: 500px) {
    display: block;

    button {
      width: 100%;
    }
  }
`;

export let Buttons = ({
  children
}: {
  children: React.ReactElement[] | React.ReactElement;
}) => {
  let childrenArray = Array.isArray(children) ? children : [children];

  return (
    <Wrapper>
      {childrenArray.map((c, i) => (
        <Fragment key={i}>
          {c} {i < childrenArray.length - 1 && <Spacer width="15px" height="10px" />}
        </Fragment>
      ))}
    </Wrapper>
  );
};
