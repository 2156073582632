import React from 'react';

export let MultiCalendarIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#3867D6"
      />
      <path
        d="M50 73.3333C62.8867 73.3333 73.3333 62.8866 73.3333 50C73.3333 37.1133 62.8867 26.6667 50 26.6667C37.1134 26.6667 26.6667 37.1133 26.6667 50C26.6667 62.8866 37.1134 73.3333 50 73.3333Z"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M50 40.6667V59.3333"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.6667 50H59.3333"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
