import React from 'react';

export let SyncIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#FC5C65"
      />
      <g clipPath="url(#clip0)">
        <path
          d="M59.3333 59.3333L50 50L40.6667 59.3333"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M50 50V71"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M69.5767 64.91C71.8525 63.6693 73.6503 61.7061 74.6864 59.3301C75.7225 56.9542 75.9378 54.3009 75.2985 51.7889C74.6591 49.277 73.2015 47.0495 71.1556 45.458C69.1097 43.8666 66.592 43.0017 64 43H61.06C60.3537 40.2682 59.0374 37.7321 57.2099 35.5823C55.3824 33.4325 53.0913 31.725 50.5089 30.5881C47.9265 29.4512 45.12 28.9145 42.3003 29.0184C39.4806 29.1223 36.7212 29.864 34.2294 31.1879C31.7377 32.5117 29.5785 34.3832 27.9141 36.6617C26.2498 38.9401 25.1236 41.5662 24.6203 44.3426C24.117 47.1189 24.2496 49.9732 25.0082 52.6909C25.7668 55.4086 27.1316 57.919 29 60.0333"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.3333 59.3333L50 50L40.6667 59.3333"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="56" height="56" fill="white" transform="translate(22 22)" />
        </clipPath>
      </defs>
    </svg>
  );
};
