import { styled } from 'buttered';

export let MainHeader = styled('h1')`
  font-size: 5em;
  font-weight: 800;

  @media screen and (max-width: 900px) {
    font-size: 3.8em;
  }

  @media screen and (max-width: 650px) {
    font-size: 2em;
  }
`;
