import { styled } from 'buttered';

export let Header = styled('header')`
  text-align: center;
  display: flex;
  flex-direction: column;
  background-size: cover;
  position: relative;
  padding-bottom: 120px;
  color: white;
`;

export let HeaderMask = styled('div')`
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 30vh;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 1;
`;

export let HeaderInner = styled('div')`
  max-width: 1200px;
  margin: 22vh auto 12vh auto;
  padding: 0px 10px;

  @media screen and (max-width: 720px) {
    margin: 110px auto max(6vh, 20px) auto;
  }
`;
