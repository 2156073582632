import React from 'react';

export let RepeatIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#45AAF2"
      />
      <g clipPath="url(#clrep6)">
        <path
          d="M61.6667 24.3333L71 33.6667L61.6667 43"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29 47.6667V43C29 40.5246 29.9833 38.1507 31.7337 36.4003C33.484 34.65 35.858 33.6667 38.3333 33.6667H71"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.3333 75.6667L29 66.3333L38.3333 57"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M71 52.3333V57C71 59.4754 70.0167 61.8493 68.2663 63.5997C66.516 65.35 64.142 66.3333 61.6667 66.3333H29"
          stroke="white"
          strokeWidth="5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clrep6">
          <rect width="56" height="56" fill="white" transform="translate(22 22)" />
        </clipPath>
      </defs>
    </svg>
  );
};
