import React from 'react';

export let SubtaskIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#10ac84"
      />
      <path
        d="M40.6667 36H71"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.6667 50H71"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.6667 64H71"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 36H29.0233"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 50H29.0233"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29 64H29.0233"
        stroke="white"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
