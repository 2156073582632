import React from 'react';

export let TodayIcon = (props: { size?: number }) => {
  return (
    <svg
      width={props.size || 30}
      height={props.size || 30}
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 50C0 8.825 8.825 0 50 0C91.175 0 100 8.825 100 50C100 91.175 91.175 100 50 100C8.825 100 0 91.175 0 50Z"
        fill="#A55EEA"
      />
      <path
        d="M66.9167 30.6667H33.0833C30.414 30.6667 28.25 32.8306 28.25 35.5V69.3333C28.25 72.0027 30.414 74.1667 33.0833 74.1667H66.9167C69.586 74.1667 71.75 72.0027 71.75 69.3333V35.5C71.75 32.8306 69.586 30.6667 66.9167 30.6667Z"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M59.6667 25.8333V35.5"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M40.3333 25.8333V35.5"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M28.25 45.1667H71.75"
        stroke="white"
        stroke-width="5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
