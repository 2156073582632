import { Center, Spacer, Text, VerticalCenter } from '@vapor/ui';
import { styled } from 'buttered';
import React from 'react';
import { Description } from '../text/description';
import { Subtitle } from '../text/subtitle';
import { BigTitle } from '../text/title';

let Wrapper = styled('section')`
  border-radius: 50px;
  display: grid;
  grid-template-columns: 45% auto;
  margin: 100px auto;
  overflow: hidden;

  @media screen and (min-width: 1030px) {
    max-width: min(1500px, 90vw);
    height: min(800px, 75vw);
  }

  @media screen and (max-width: 1030px) {
    margin: 30px 10px;
    display: block;
    border-radius: 35px;
  }

  @media screen and (max-width: 700px) {
    border-radius: 20px;
  }
`;

let Content = styled('main')`
  padding: 40px;
`;

let ContentInner = styled('main')`
  margin: 0px auto;

  @media screen and (min-width: 600px) {
    width: 450px;
  }
`;

let ImageWrapper = styled('div')`
  padding: 40px;
  background: rgba(0, 0, 0, 0.05);

  img {
    image-rendering: -webkit-optimize-contrast;
    width: 350px;
  }

  @media screen and (max-width: 600px) {
    img {
      width: 90%;
    }
  }
`;

export let BigCard = ({
  image,
  title,
  description,
  children,
  background,
  color
}: {
  image: React.ReactElement;
  title: React.ReactNode;
  description: React.ReactNode;
  children: React.ReactNode;
  background?: string;
  color?: string;
}) => {
  return (
    <Wrapper style={{ background, color }}>
      <ImageWrapper>
        <Center>{image}</Center>
      </ImageWrapper>
      <Content>
        <VerticalCenter>
          <ContentInner>
            <BigTitle>{title}</BigTitle>

            <Spacer height="2em" />

            <Subtitle>{description}</Subtitle>

            <Spacer height="2em" />

            <Description>{children}</Description>
          </ContentInner>
        </VerticalCenter>
      </Content>
    </Wrapper>
  );
};
